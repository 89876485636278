.header{
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--main-bg-color);
    transition: all .5s;
}

.header.open{
    background-color: #1a1a1a00;
}

.menu{
    width: fit-content;
    max-width: 95%;
    height: fit-content;
    padding: 40px;
    border-radius: 40%;
    /* background-color: #0e9ec2;
    box-shadow: 0px 0px 30px 30px #0e9ec2; */
    background-color: rgba(255, 255, 255, 0.685);
    box-shadow: 0px 0px 30px 30px rgba(255, 255, 255, 0.685);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    opacity: 0;
    transition: all .3s;
    display: none;
}

.header.open .menu{
    opacity: 1;
    display: flex;
}

.menu a{
    font-size: 24px;
    color: black;
    opacity: 0.8;
    text-decoration: none!important;
    transition: all .3s;
    font-weight: 600;
    transition: all .3s;
}

.menu a:hover{
    opacity: 1;
}

.controls{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
}

.arrow{
    font-size: 30px;
    font-weight: 800;
    color: white;
    text-decoration: none!important;
    transition: all .3s;
}

.arrow:hover{
    text-shadow: 1px 1px 2px white;
}

.active_link{
    color: black!important;
    text-shadow: 2px 2px 2px rgba(59, 59, 59, 0.342);
    opacity: 1!important;
}