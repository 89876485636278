.layout{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-areas: 
    'body header'
    'footer footer';
    grid-template-columns: 1fr 55px;
    grid-template-rows: 6fr 0fr;
    transition: all .3s;
    background: #0B132B;
    background-size: cover!important;
}

.header{
    grid-area: header;
    min-width: 55px;
}

.body{
    grid-area: body;
    /* background-color: #1C2541; */
    transition: all .5s;
    transform-style: preserve-3d;
    transform-origin: 20%;
    overflow: hidden;
    box-sizing: border-box;
    background-color: var(--main-bg-color);
}

.footer{
    grid-area: footer;
    background-color: green;
}

.layout.menu_mode{
    grid-template-columns: 1fr 300px;
}

.layout.menu_mode .body{
    scale: 0.8;
    transform: perspective(1000px) rotateY(15deg) translateX(10%);
    border-radius: 40px;
    box-shadow: #FBFAFD 0px 4px 20px 0px;
    border: 10px solid white;
}

.layout.solid_mode{
    --main-bg-color: #1a1a1a;
}