.button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: none;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    box-shadow: none;
  }
  .button {
    width: 12rem;
    height: auto;
  }
  .button .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 3rem;
    height: 3rem;
    background: white;
    border-radius: 1.625rem;
  }
  .button .circle .arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #282936;
  }
  .button .circle .arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.625rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
  }
  .button .circle .arrow::before {
    position: absolute;
    content: "";
    top: -0.25rem;
    right: 0.0625rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #282936;
    border-right: 0.125rem solid #282936;
    transform: rotate(45deg);
  }
  .button .title {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0.75rem 0;
    margin: 0 0 0 1.85rem;
    color: white;
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    /* text-transform: uppercase; */
  }
  .button:hover .circle {
    width: 100%;
  }
  .button:hover .circle .icon.arrow {
    background: #282936;
    transform: translate(1rem, 0);
  }
  .button:hover .title {
    color: #282936;
  }