.tags{
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
}

.tag{
    padding: 5px 15px;
    border-radius: 15px;
    color: rgb(51, 50, 56);
    cursor: pointer;
    transition: all .3s;
    background-color: white;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
}

.tag:hover{
    box-shadow: #FBFAFD 0px 0px 10px 0px;
}