@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500&family=Open+Sans:wght@300;400;600;800&family=Ubuntu:wght@300;400;500;700&display=swap');

:root {
    --main-bg-color: #1a1a1ab2;
    --hidden-bg-color: #1a1a1a00;
    --dark-text-color: rgb(51, 50, 56);
}

body, html {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 400;
    color: white;
}

h1{
    line-height: normal;
}

.page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* background-color: #1C2541; */
}