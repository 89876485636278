.block{
    position: absolute;
    width: 30px;
    height: 50px;
    top: 120px;
    right: 20px;
    background: white;
    border-radius: 15px;
    cursor: pointer;
}

.block img{
    object-fit: contain;
    width: 70%;
    height: auto;
    transition: opacity .3s;
    animation: rotating 2s linear infinite;
    opacity: 1;
    margin-bottom: 4px;
}

.block *{
    pointer-events: none;
    user-select: none;
}

@keyframes rotating {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

.toggler {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black;
    top: 0;
    left: 0;
    transition: all .3s;
    display: flex;
    clip-path: circle(33% at 50% 70%);
    justify-content: center;
    align-items: flex-end;
}

.toggler.active{
    clip-path: circle(33% at 50% 29%);
}

.toggler.active img{
    opacity: 0;
}

.background{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--main-bg-color);
    top: 0;
    left: 0;
    opacity: 0.8;
}