.main{
    position: relative;
}

.carousel{
    overflow: hidden;
    width: 75vw;
    height: 600px;
    display: grid;
    grid-template-areas: 'b b' 'a c';
    grid-template-columns: 500px auto;
    gap: 20px;
    grid-template-rows: 104px auto;
}

.preview{
    grid-area: a;
    width: 500px;
    height: 600px;
}
.preview img{
    width: 100%;
    height: 600px;
    object-fit: cover;
    object-position: left top;
    transition: all .3s;
    animation: preview 1s;
}

.items{
    grid-area: b;
    display: grid;
    grid-auto-flow: column;
    gap: 20px;
    position: absolute;
    width: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    /* scroll-behavior:auto; */
}
.items::-webkit-scrollbar {
    display: none;
}
.item{
    width: 200px;
    height: 100px;
    min-width: 200px;
    min-height: 100px;
    max-width: 500px;
    max-height: 600px;
    display: block;
    position: relative;
    border: 2px solid white;
}
.item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    -webkit-user-drag: none;
    transition: all .3s;
    user-select: none;
}
.item.active{
    border: 2px solid gold;
}

.empty{
    min-width: calc(75vw - 500px);
    height: 1px;
    display: block;
}

.info{
    grid-area: c;
    position: relative;
    z-index: 2;
    transition: all .3s;
}

.hidden{
    opacity: 0;
    pointer-events: none;
}