.content{
    display: inline-flex;
    gap: 40px;
    align-items: start;
    flex-wrap: wrap;
}

.git{
    display: flex;
    flex-direction: column;
    background-color: #FBFAFD;
    border-radius: 40px;
    padding: 40px;
    box-shadow: inset 0 0 6px 6px #1c254150;
    border: solid 4px white;
    color: rgb(51, 50, 56);
    max-width: 30vw;
    min-width: 400px;
}

.profile{
    display: inline-flex;
    gap: 40px;
}

.profile *{
    margin: 0;
}

.profile .info{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.description{
    max-width: 50vh;
    font-size: 20px;
    line-height: 25px;
    min-width: 400px;
}